import {
  AcceptConsentRequest,
  AcceptConsentResponse,
  AcceptLoginResponse,
  AcceptLogoutRequest,
  AcceptLogoutResponse,
  HydraShell,
  InitialConsentRequest,
  InitialConsentResponse,
  InitialLoginRequest,
  InitialLoginResponse,
  InitialLogoutRequest,
  InitialLogoutResponse,
} from '@weave/schema-gen-ts/dist/schemas/hydra-shell/hydra_shell.pb';
import appConfig from '@frontend/env';
import { getDecodedWeaveToken, localStorageHelper } from '@frontend/auth-helpers';

const backendApi = appConfig.BACKEND_API;

export const APIfetchNoAuth = (): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
  return (url: string, reqInit: RequestInit) => {
    return fetch(`${backendApi}${url}`, reqInit).then((res) => {
      if (res.status !== 200) {
        throw new Error(res.body?.toString());
      }
      return res.json();
    });
  };
};

const authorizedFetch = (token: string): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
  return (url: string, reqInit: RequestInit) => {
    reqInit.headers = {
      ...reqInit.headers,
      Authorization: `Bearer ${token}`,
    };
    return fetch(`${backendApi}${url}`, reqInit).then((res) => {
      if (res.status !== 200) {
        return res.json().then((resBody: { code: number; message: string }) => {
          throw new Error(resBody.message);
        });
      }
      return res.json();
    });
  };
};

const logoutChallenge = 'logout_challenge';
const loginChallenge = 'login_challenge';
const consentChallenge = 'consent_challenge';

class HydraShellService {
  private challenge: string | undefined;
  private cancel: string | undefined;

  // getCurrentState will attempt to identify if we are starting a new login flow, or if we are in the middle of a login flow
  public getCurrentState(): string {
    const consentChallenge = this.getConsentChallenge();
    const loginChallenge = this.getLoginChallenge();
    const logoutChallenge = this.getLogoutChallenge();
    if (!!consentChallenge) {
      return 'consent';
    }
    if (!!loginChallenge) {
      return 'login';
    }
    if (!!logoutChallenge) {
      return 'logout';
    }
    return 'none';
  }

  public initializeOAuth2Flow(): Promise<InitialLoginResponse> {
    this.challenge = this.getLoginChallenge();
    return HydraShell.InitialOAuth2LoginRequest(
      APIfetchNoAuth() as (url: string, reqInit: RequestInit) => Promise<InitialLoginResponse>,
      { challenge: this.challenge } as InitialLoginRequest
    )
      .then((res: InitialLoginResponse) => {
        if (!!res.subject) {
          this.cancel = res.subject;
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
        throw new Error(err);
      });
  }

  public acceptOAuth2Flow(weaveJWT: string): Promise<AcceptLoginResponse> {
    const decodedWeaveToken = getDecodedWeaveToken();
    const challenge = this.challenge || this.getLoginChallenge();

    if (!challenge) {
      throw new Error('No challenge found');
    }
    // success logging in the token is stored in local storage
    const acceptOAuth2LoginRequestBody = {
      challenge: this.challenge || this.getLoginChallenge(),
      subject: decodedWeaveToken?.username,
      rememberFor: 4 * 60 * 60, // When the session expires, in seconds. Set this to 0 so it will never expire. Backend may or may not prevent this.
      // From the Hydra docs:
      // Sets which "level" (e.g. 2-factor authentication) of authentication the user has. The value is really arbitrary
      // and optional. In the context of OpenID Connect, a value of 0 indicates the lowest authorization level.
      // acr: '0',
      // If that variable is not set, the ACR value will be set to the default passed here ('0')
      acr: '0',
      cancel: '',
    };
    if (!!this.cancel && acceptOAuth2LoginRequestBody.subject !== this.cancel) {
      acceptOAuth2LoginRequestBody.cancel = this.cancel;
    }
    return HydraShell.AcceptOAuth2LoginRequest(
      authorizedFetch(weaveJWT) as (url: string, reqInit: RequestInit) => Promise<AcceptLoginResponse>,
      acceptOAuth2LoginRequestBody
    )
      .then((res) => {
        localStorageHelper.delete(loginChallenge);
        return res;
      })
      .catch((err: Error) => {
        // if error contains 'login request was cancelled' then we need to redirect to the originating app in order to get a new login challenge
        // this is because the user previously logged in is different from the current user and hydra will need a different session token
        console.error(err);
        if (err.message.includes('login request was cancelled')) {
          throw new Error('login request was cancelled');
        }
        throw new Error('Error accepting login request');
      });
  }

  public initializeConsentFlow(weaveJWT: string, challenge?: string): Promise<InitialConsentResponse> {
    this.challenge = challenge ?? this.getConsentChallenge();
    return HydraShell.InitialOAuth2ConsentRequest(
      authorizedFetch(weaveJWT) as (url: string, reqInit: RequestInit) => Promise<AcceptConsentResponse>,
      {
        challenge: this.challenge,
      } as InitialConsentRequest
    )
      .then((res: InitialConsentResponse) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        throw new Error(err);
      });
  }

  public acceptConsentFlow(
    weaveJWT: string,
    scope: string[],
    clientID: string | undefined,
    challenge?: string
  ): Promise<AcceptLoginResponse> {
    this.challenge = challenge ?? this.getConsentChallenge();
    return HydraShell.AcceptOAuth2ConsentRequest(
      authorizedFetch(weaveJWT) as (url: string, reqInit: RequestInit) => Promise<AcceptLoginResponse>,
      {
        challenge: this.challenge,
        scope: scope,
        clientId: clientID,
      } as AcceptConsentRequest
    ).then((res: AcceptConsentResponse) => {
      localStorageHelper.delete(consentChallenge);
      if (!!res.redirectTo) {
        window.location.href = res.redirectTo;
      }
      return res;
    });
  }

  public initializeLogoutFlow(weaveJWT: string): Promise<InitialLogoutResponse> {
    return HydraShell.InitialOAuth2LogoutRequest(
      authorizedFetch(weaveJWT) as (url: string, reqInit: RequestInit) => Promise<InitialLogoutResponse>,
      {
        challenge: this.getLogoutChallenge(),
      } as InitialLogoutRequest
    ).then((res: InitialLogoutResponse) => {
      return res;
    });
  }

  public acceptLogoutFlow(weaveJWT: string): Promise<AcceptLogoutResponse> {
    return HydraShell.AcceptOAuth2LogoutRequest(
      authorizedFetch(weaveJWT) as (url: string, reqInit: RequestInit) => Promise<AcceptLogoutResponse>,
      {
        challenge: this.getLogoutChallenge(),
      } as AcceptLogoutRequest
    ).then((res: AcceptLogoutResponse) => {
      localStorageHelper.delete(logoutChallenge);
      if (!!res.redirectTo) {
        window.location.href = res.redirectTo;
      }
      return res;
    });
  }

  public getLoginChallenge(): string {
    // get challenge from query string param named 'login_challenge'
    const urlParams = new URLSearchParams(window.location.search);
    const challenge = urlParams.get(loginChallenge);
    if (!challenge) {
      if (window.location.href.includes('/sign-in/callback')) {
        const challengeFromLocalStorage = localStorageHelper.get(loginChallenge);
        if (!!challengeFromLocalStorage) {
          return challengeFromLocalStorage;
        }
      }
      return '';
    }
    localStorageHelper.create(loginChallenge, challenge);
    return challenge;
  }

  private getConsentChallenge(): string {
    // get challenge from query string param named 'consent_challenge'
    const urlParams = new URLSearchParams(window.location.search);
    const challenge = urlParams.get(consentChallenge);
    if (!challenge) {
      return '';
    }
    localStorageHelper.create(consentChallenge, challenge);
    return challenge;
  }

  private getLogoutChallenge(): string {
    // get challenge from query string param named 'logout_challenge'
    const urlParams = new URLSearchParams(window.location.search);
    const challenge = urlParams.get(logoutChallenge);
    if (!challenge) {
      // if no challenge, check local storage (we are likely returning from the workforce logout)
      const challengeFromLocalStorage = localStorageHelper.get(logoutChallenge);
      if (!!challengeFromLocalStorage) {
        return challengeFromLocalStorage;
      }
      return '';
    }
    localStorageHelper.create(logoutChallenge, challenge);
    return challenge;
  }
}

const hydraShellService = new HydraShellService();
export { hydraShellService };

export type { HydraShellService };
