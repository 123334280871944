import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  AuthMethod,
  AuthService,
  InitiateAuthResponse,
  MFAMethod,
  VerifyAuthResponse,
} from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { AuthStorage, localStorageHelper } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { EmailIcon, Heading, MessageIcon, NakedButton, PrimaryButton, Text, TextLink } from '@frontend/design-system';
import { APIfetchNoAuth } from '../services';
import { MFAInput } from './mfa-input.component';
import { SignInError } from './sign-in-error.component';

type Props = {
  setMfaEnabled: (value: boolean) => void;
  mfaMethods: MFAMethod[];
  handleSignIn: (weaveJWT: string) => void;
  challenge: string;
  showDisplayNames: boolean;
  onToggleDisplayNames: (value: boolean) => void;
};

export const MFAForm = ({ mfaMethods, handleSignIn, challenge, showDisplayNames, onToggleDisplayNames }: Props) => {
  const { t } = useTranslation('SignIn');
  const [code, setCode] = useState<string>();
  const [disableAction, setDisableAction] = useState(true);
  const [selectedContact, setSelectedContact] = useState(mfaMethods[0]);
  const [resendTimer, setResendTimer] = useState(15);
  const [canResend, setCanResend] = useState(false);
  const [errorCode, setErrorCode] = useState<number | string>();
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const handleMFAError = (error: string) => {
    setIsError(true);
    setErrorCode(error);
  };

  const handleErrorHide = (value: boolean) => {
    setIsError(value);
  };

  const handleCodeVerification = () => {
    setLoading(true);
    setLoadingMessage(t('Verifying code...'));
    AuthService.VerifyAuth(APIfetchNoAuth() as (url: string, reqInit: RequestInit) => Promise<VerifyAuthResponse>, {
      data: {
        code: code,
      },
      challenge,
    })
      .then((res) => {
        setLoading(false);
        if (res.token) {
          localStorageHelper.create(AuthStorage.weave_token, res.token);
          handleSignIn(res.token);
        } else {
          throw new Error('No token returned');
        }
      })
      .catch((err) => {
        setLoading(false);
        handleMFAError('invalidMFACode');
        console.error('Failed to verify contact', err);
      });
  };

  const handleNewDisplayName = (method: MFAMethod) => {
    onToggleDisplayNames(false);
    // Reset the timer and disable the button
    setResendTimer(15);
    setCanResend(false);
    setLoading(true);
    setLoadingMessage(t('Sending new code...'));
    AuthService.InitiateAuth(APIfetchNoAuth() as (url: string, reqInit: RequestInit) => Promise<InitiateAuthResponse>, {
      method: {
        id: method.id,
        displayName: method.displayName,
        type: method.type,
      },
      challenge,
    })
      .then(() => {
        setLoading(false);
        setSelectedContact(method);
      })
      .catch((err) => {
        setLoading(false);
        console.error('Failed to send a new code', err);
        handleMFAError('codeSendFailure');
      });
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setTimeout(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    } else {
      setCanResend(true);
    }
    return undefined;
  }, [resendTimer]);

  return (
    <>
      {!showDisplayNames ? (
        <section
          css={css`
            width: 400px;
            align-self: center;
          `}
        >
          <Heading level={2}>
            {selectedContact?.type === AuthMethod.EMAIL ? t('We Emailed You a Code') : t('We Texted You a Code')}
          </Heading>
          <Text
            css={css`
              margin-top: ${theme.spacing(1)};
            `}
            color='light'
          >
            {t(`Enter the verification code sent to ${selectedContact?.displayName} `)}
          </Text>

          <div
            css={css`
              width: 350px;
              display: flex;
              flex-direction: column;
              margin-top: ${theme.spacing(4)};
            `}
          >
            <MFAInput setCode={setCode} setDisableAction={setDisableAction} onSubmit={handleCodeVerification} />
            <PrimaryButton
              css={css`
                margin-top: ${theme.spacing(3)};
              `}
              onClick={() => handleCodeVerification()}
              disabled={disableAction}
            >
              {loading ? loadingMessage : t('Log In')}
            </PrimaryButton>
            <div
              css={css`
                margin-top: ${theme.spacing(4)};
                display: block;
                text-align: center;
                gap: ${theme.spacing(1)};
              `}
            >
              {t('Didn’t get your code ? ')}
              {!canResend ? (
                <Text>{t(`Send a new code in ${resendTimer} seconds`)}</Text>
              ) : (
                <TextLink
                  css={css`
                    cursor: pointer;
                  `}
                  onClick={() => handleNewDisplayName(selectedContact)}
                >
                  {t('Send a new code')}
                </TextLink>
              )}
            </div>
          </div>

          {mfaMethods && mfaMethods.length > 1 && (
            <Text
              css={css`
                margin: ${theme.spacing(2, 1)};
              `}
            >
              {t('Having Troubles ? ')}
              <TextLink onClick={() => onToggleDisplayNames(true)}>{t('Change verification method')}</TextLink>
            </Text>
          )}
          {isError && <SignInError errorCode={errorCode} onErrorHide={handleErrorHide} />}
        </section>
      ) : (
        <section
          css={css`
            width: 400px;
            align-self: center;
          `}
        >
          <Heading
            css={css`
              margin: ${theme.spacing(0, 6)};
            `}
            level={3}
          >
            {t('Select a Verification Method')}
          </Heading>
          {mfaMethods &&
            mfaMethods.map((method) => (
              <section
                css={css`
                  margin-top: ${theme.spacing(4)};
                `}
                onClick={() => handleNewDisplayName(method)}
                key={method.id}
              >
                <NakedButton
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid ${theme.colors.neutral20};
                    border-radius: ${theme.borderRadius.medium};
                    padding: ${theme.spacing(2)};
                    width: 100%;
                    gap: ${theme.spacing(2)};
                    margin-top: ${theme.spacing(2)};
                    font-size: ${theme.font.size.medium};
                    font-weight: ${theme.font.weight.bold};
                    color: ${theme.colors.neutral70};
                    &:hover {
                      background-color: ${theme.colors.neutral10};
                    }
                  `}
                >
                  {method.type === 'EMAIL' ? <EmailIcon /> : <MessageIcon />}
                  {t(`Send Code to ${method.displayName}`)}
                </NakedButton>
              </section>
            ))}
        </section>
      )}
    </>
  );
};
