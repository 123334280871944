import { VerifyAuthResponse } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { authnClient, resetPassword } from '@frontend/auth';

export const sendPasswordReset = async (username: string): Promise<void> => {
  return resetPassword(username).then(() => {}); // ignore response, forward any errors
};

export const signInWithPassword = async (username: string, password: string): Promise<VerifyAuthResponse | string> => {
  authnClient.changeAuthMethod('legacy');
  return authnClient.signIn({ username, password });
};
