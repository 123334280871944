import { useState } from 'react';
import { css } from '@emotion/react';
import { weaveBG, weaveBGPassword, weaveLogoDark } from '@frontend/assets';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { LoginFormComponent } from '../components/login-form.component';

export default function UsernameForm() {
  const [isShowingBGImage, setIsShowingBGImage] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        flex-wrap: wrap;
      `}
    >
      <div
        css={css`
          width: 40%;
          padding: ${theme.spacing(2)};
          position: relative;
          @media screen and (max-width: ${breakpoints.medium.max}px) {
            width: 100%;
          }
        `}
      >
        <img
          src={weaveLogoDark}
          alt='weave-app-logo'
          css={css`
            height: ${theme.spacing(3.5)};
            @media screen and (max-width: ${breakpoints.small.max}px) {
              position: absolute;
              left: 5%;
            }
          `}
          data-tour-guide='portal-logo'
        />
        <LoginFormComponent setIsShowingBGImage={setIsShowingBGImage} />
      </div>
      <div
        css={css`
          width: 60%;
          flex: auto;
          @media screen and (max-width: 1400px) {
            flex: 1;
          }
          @media screen and (max-width: 1100px) {
            display: none;
          }
        `}
      >
        <img
          css={css`
            height: 100%;
            object-fit: cover;
            @media screen and (min-width: 1800px) {
              position: relative;
              left: 5%;
              height: ${theme.spacing(125)};
            }
          `}
          src={isShowingBGImage ? weaveBGPassword : weaveBG}
        />
      </div>
    </div>
  );
}
